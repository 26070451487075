.p-inputtext:enabled:focus {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-inputtext:enabled:focus-visible {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-checkbox .p-checkbox-box .checkdark {
    background: #000000 !important;
}

.p-checkbox .p-checkbox-box .checklight {
    background: #ffffff !important;
}

.loginviewdarkweb {
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #000000;
    border-radius: 10px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.loginInputDarkweb .p-inputtext {
    background-color: #14161b !important;
    background: #14161b;
    border-width: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: left;
    width: 300px;
    color: #ffffff;
}

.loginbuttondarkweb {
    width: 300px;
    padding: 10px;
    background-color: #2139b7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}

.loginviewlightweb {
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 10px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginInputLightweb .p-inputtext {
    background-color: #f2f2f2 !important;
    background: #f2f2f2;
    border-width: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: center;
    width: 300px;
    color: #000000;
}

.loginbuttonlighwebt {
    width: 300px;
    padding: 10px;
    background-color: #2139b7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}