.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0 #ffa800;
}

.logininput:focus {
  border-width: 2px;
  border-color: #ef9700;
}

.p-inputtext:enabled:focus {
  outline: 0px;
  outline-offset: 0px;
  border-color: none;
  box-shadow: none !important;
}
.p-inputtext:enabled:focus-visible {
  outline: 0px;
  outline-offset: 0px;
  border-color: none;
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box .checkdark {
  background: #000000 !important;
}
.p-checkbox .p-checkbox-box .checklight {
  background: #ffffff !important;
}
.loginInputDarkSearch .p-inputtext {
  background-color: #14161b !important;
  background: #14161b;
  border-width: 0;
  margin-right: 20px;
  align-self: center;
  text-align: center;
  width: 200px;
  color: #ffffff;
}

.loginInputLightSearch .p-inputtext {
  background-color: #f2f2f2 !important;
  background: #f2f2f2;
  border-width: 0;
  margin-right: 20px;
  align-self: center;
  text-align: center;
  width: 200px;
  color: #000000;
}
