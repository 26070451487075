.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}

.scrolldivu {
  overflow-y: scroll;
  height: 100%;
}

.scrolldivu::-webkit-scrollbar {
  display: none;
}

.scrolldiv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}