.p-inputtext:enabled:focus {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-inputtext:enabled:focus-visible {
    outline: 0px;
    outline-offset: 0px;
    border-color: none;
    box-shadow: none !important;
}

.p-checkbox .p-checkbox-box .checkdark {
    background: #000000 !important;
}

.p-checkbox .p-checkbox-box .checklight {
    background: #ffffff !important;
}

.loginviewdark {
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #14161b;
    border-radius: 10px;
    width: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;

}

.loginInputDark .p-inputtext {
    background-color: #000000 !important;
    background: #000000;
    border-width: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: left;
    width: 230px !important;
    color: #ffffff;
}

.loginbuttondark {
    width: 230px !important;
    padding: 10px;
    background-color: #2139b7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}

.loginviewlight {
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 10px;
    width: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginInputLight .p-inputtext {
    background-color: #f2f2f2 !important;
    background: #f2f2f2;
    border-width: 0;
    margin-bottom: 20px;
    align-self: center;
    text-align: center;
    width: 230px !important;
    color: #000000;
}

.loginbuttonlight {
    width: 300px;
    padding: 10px;
    background-color: #2139b7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}

/* 
@media only screen and (min-width: 300px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        background-color:#000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
}

@media only screen and (min-width: 500px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 90%;
        width: 80%;
        background-color:#000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
}


@media only screen and (min-width: 700px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 90%;
        width: 80%;
        background-color:#000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
}

@media only screen and (min-width: 1024px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 80%;
        width: 50%;
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
}

@media only screen and (min-width: 1100px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 80%;
        width: 50%;
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
}

@media only screen and (min-width: 2000px) {
    .loginview{
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        height: 80%;
        width: 50%;
        background-color: #000000;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .loginInputDark .p-inputtext {
        background-color: #14161b !important;
        background:#14161b;
        border-width:0;
        margin-bottom: 20px;
        align-self: center;
        text-align: center;
        width:300px;
        color: #ffffff;
    }
    .loginbutton{
        width: 300px;
        padding: 10px;
        background-color: #2139b7;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        color: #ffffff;
    }
} */