
.p-dialog .p-dialog-content:last-of-type{
    border-radius: 10px !important;
}

.p-dialog .p-dialog-content{
    background-color: #000000 !important;
    padding: 0 0 0 0;
    border-style: solid;
    border-width: 2px;
    border-color: #2139b7;
}
